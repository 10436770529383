<template>
    <a-modal :title="modalTitle" :visible="controller.show" :width="700" @cancel="close" v-if="controller">

        <a-steps :current="createStep" size="small" class="mb-4" v-if="controller.envelope && !newDoc.isManual">
            <a-step title="Choose Template" />
            <a-step title="Signing Order" />
        </a-steps>
        <transition name="slide-fadein-right" mode="out-in">

            <a-form-model ref="newDoc" :model="newDoc" v-if="createStep === 0" :key="1">
                <div>
                    <a-form-model-item prop="isManual" v-if="controller.envelope" :rules="{required:true}">
						<template slot="label">
							Signing Method
							<a-tooltip overlayClassName="change-tooltip-color" placement="right">
								<template slot="title" style="width: 400px;">
									Select e-signature if at least one party needs to sign digitally.
								</template>
								<a-icon type="question-circle"
									style="font-size: 14px; color: black; margin-left: 2px;" />
							</a-tooltip>
						</template>
                        <a-radio-group v-model="newDoc.isManual">
                            <a-radio-button :value="false">
                                <a-icon type="file-protect" /> E-Signature
                            </a-radio-button>
                            <a-radio-button :value="true">
                                <a-icon type="highlight" /> Print &amp; Sign
                            </a-radio-button>
                        </a-radio-group>
                    </a-form-model-item>
                    <p v-if="!integrated.active && !newDoc.isManual" class="aC py-1 mb-0 bg-danger text-white rounded" style="display:inline-flex; max-width:600px">
                        <a-icon class="text-lg" type="warning" />
                        <span class="mx-3">
                            You are not currently integrated with our signing service.
                            Please check your <router-link class="text-primary" to="/settings?docusign">settings</router-link> to integrate with a signing service.
                            Alternatively, you can choose the <strong>Print &amp; Sign</strong> option.
                        </span>
                    </p>
                </div>

                <a-form-model-item label="Select Document Template" prop="template" :rules="{required:true}">
                    <a-select :size="$mq === 'lg' ? null : 'large'" option-label-prop="label" v-model="newDoc.template" style="max-width:300px;">
                        <a-select-option :value="template.id" v-for="template in contractTemplates" :key="template.id" :label="template.name">
                            <div class="dF aC template-list">
                                <div>
                                    <i class="fe fe-file-text" style="font-size:15px;"/>
                                </div>
                                <div class="f1 ml-2">
                                    {{template.name}}
                                    <small class=block>{{template.pages.length}} pages contract</small>
                                </div>
                            </div>
                        </a-select-option>
                    </a-select>
                </a-form-model-item>

				<a-form-model-item prop="docType" v-if="controller.type === 'regular' && newDoc.template && controller.isAmendment">
					<template slot="label">
						Document Type
						<a-tooltip overlayClassName="change-tooltip-color" placement="right">
							<template slot="title" style="width: 400px;">
								<p>
									&#x2022; &nbsp;&nbsp;Amendments affect the APS agreement. When an amendment is issued it will update the transaction status to pending. Once all the required parties execute the amendment the transaction status will be updated to complete.
								</p>
								<p>
									&#x2022; &nbsp;&nbsp;Documents do not affect the APS agreement. All the required parties just need to sign the document. It will not update the transaction status.
								</p>
							</template>
							<a-icon type="question-circle"
								style="font-size: 14px; color: black; margin-left: 2px;" />
						</a-tooltip>
					</template>
                    <a-radio-group v-model="newDoc.docType">
                        <a-radio-button value="amendment">
                            <a-icon type="file-protect" /> Amendment
                        </a-radio-button>
                        <a-radio-button value="supporting">
                            <a-icon type="highlight" /> Documents
                        </a-radio-button>
                    </a-radio-group>
                </a-form-model-item>

                <div class="dF fC aC jC" v-if="loadModal">
                    <a-icon type="loading" style="font-size:30px;" />
                    <p>Analyzing Document for Relevant Questions...</p>
                </div>

                <template v-else-if="newDoc.template">

                    <a-form-model-item prop="name" label="Document Name" v-if="controller.type === 'regular'">
                        <a-input v-model="newDoc.name" />
                    </a-form-model-item>

                    <a-card title="Inclusions" v-if="contractQuestions.inclusions && inclusions.length">
                        <a-form-model-item prop="inclusions" label="Choose Inclusions" >
                            <a-checkbox-group v-model="newDoc.questions.inclusions" :options="inclusions"  />
                        </a-form-model-item>
                    </a-card>

                    <a-card class="mt-3" title="Deposit Structure" v-if="contractQuestions.deposits > 0">
                        <a-row :gutter="16">
                            <a-col :span="2">
                                <a-form-model-item :label="`#`" class="mb-0">
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-model-item :label="`Deposit Date`" class="mb-0">
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-model-item :label="`Deposit Amount`" class="mb-0">
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row  :gutter="16" v-for="i in 3" :key="i + 'laksdjf'" class="mb-2">
							<div v-if="newDoc.questions && newDoc.questions.deposits && newDoc.questions.deposits[i-1]">
	                            <a-col :span="2">
	                                <a-form-model-item :prop="`deposits.${i-1}.date`" class="mb-0">
	                                    <a-input disabled :value="i" />
	                                </a-form-model-item>
	                            </a-col>
	                            <a-col :span="6">
	                                <a-form-model-item :prop="`deposits.${i-1}.date`" class="mb-0" >
	                                    <a-date-picker :disabled-date="disabledDate" format="MMM DD, YYYY" v-model="newDoc.questions.deposits[i-1].date" />
	                                </a-form-model-item>
	                            </a-col>
	                            <a-col :span="6">
	                                <a-form-model-item :prop="`deposits.${i-1}.amount`" class="mb-0" >
	                                    <a-input-number  :min="0"
	                                    @change="checkBalance"
	                                    :formatter="value => '$ ' + formatPrice(value)"
	                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" v-model="newDoc.questions.deposits[i-1].amount" style="width:100%"/>
	                                </a-form-model-item>
	                            </a-col>
							</div>
                        </a-row>
                        <a-row>
                            <a-col :span="6">
                                <a-form-model-item :label="`Balance due on ${closingDateString}`" class="mt-2">
                                    <a-input-number
                                        :formatter="value => '$ ' + formatPrice(value)"
                                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                                        disabled :value="newDoc.questions.balance" />
                                        <div slot="extra" class="text-danger" v-if="newDoc.questions.balance < 0">
                                            There might be an issue with the payment structure. Please review.
                                        </div>
                                        <div slot="extra" class="text-danger" v-else-if="depositClosingIssue">
                                            <span v-if="depositClosingIssue === 'before'">
                                                A Deposit date cannot be later than the closing date. Please review.
                                            </span>
                                            <span v-else-if="depositClosingIssue === 'working'">
                                                A Deposit Date must not fall on a Holiday or a Weekend. Please review your deposit structure.
                                            </span>

                                        </div>
                                </a-form-model-item>

                            </a-col>
                        </a-row>

                    </a-card>

                    <a-card title="Dynamic Purchaser" v-if="contractQuestions.choosePurchaser && currentTransaction.purchasers.length">
                        <a-form-model-item class="mb-0" label="Choose a purchaser">
                            <a-select v-model="newDoc.questions.purchaserX" @change="setPurchaserX">
                                <a-select-option v-for="purchaser in currentTransaction.purchasers" :value="purchaser.id" :key="purchaser.id">{{purchaser.firstName}} {{purchaser.lastName}}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-card>


                    <a-card title="Custom Fields" v-if="customFields.length" class="mt-3">

                        <a-form-model-item v-for="item in customFields" :key="'halkdsjf' + item.value" :label="item.name || 'Unnamed Question'" :val="`newDoc.questions.customFields['${item.value}']`">
                            <template v-if="item.type === 'text'">
                                <a-input v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]"  />
                            </template>

                            <template v-else-if="item.type === 'checkmark'">
                                <a-switch v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]" />
                            </template>
                            <!-- <template v-else-if="item.type === 'multiple'">
                                <a-select v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]">
                                    <a-select-option v-for="opt in item.configure.options" :key="opt.value" :value="opt.value">{{opt.value}}</a-select-option>
                                </a-select>
                            </template> -->
                            <template v-else-if="item.type === 'multiple'">
                                <a-select :value="newDoc.customFields[item.value.replace('other.customFields.', '')]" @change="(e) => selectMultiple(e, item.value.replace('other.customFields.', ''))">
                                    <a-select-option v-for="opt in item.configure.options" :key="opt.value" :value="opt.value">{{opt.value}}</a-select-option>
                                </a-select>
                            </template>
							<template v-else-if="item.type === 'date'">
								<a-date-picker format="MMM DD, YYYY" v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]" />
							</template>
							<template v-else-if="item.type === 'inclusion'">
								<a-select mode="multiple" v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]">
									<a-select-option v-for="(inclusion, index) in inclusions" :key="inclusion.value+index" :value="inclusion.body" :label="inclusion.label">{{inclusion.label}}</a-select-option>
								</a-select>
		                   </template>
                            <template v-else>
                                <ImageBoxSelector v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]" />
                            </template>
                        </a-form-model-item>

                    </a-card>
                </template>



            </a-form-model>
            <div class="mt-4" v-if="createStep === 1" :key="2">
                <div>
                    <div class="dF fC aC" v-if="showRoute">
                        <div  style="position:sticky; top:10px">
                            <h4 class="text-center mt-2">Sign Route Preview</h4>
                            <SignRoutePreview :data="signRoutePreview"/>
                        </div>
                    </div>
                    <div style="max-width:750px;" v-else>

						<!-- <a-checkbox v-model="requiresRepReview" class="ml-4 mb-4">
							Requires Rep to Review Contract
						</a-checkbox>

						<template v-if="requiresRepReview">
		                    <SigningParty v-model="reviewer" :repReviewer="requiresRepReview"/>
							<a-divider />
		                </template> -->

						<!-- sellers agent router -->
						<template v-if="signing.sellerSide && signing.sellerSide.length">
                            <template v-for="(signer,signerI) in signing.sellerSide" >
                                <template v-if="!signer.hidden">
                                    <SigningParty :showError="showError" v-model="signing.sellerSide[signerI]" :key="signer.id"/>
                                </template>
                            </template>
							<a-divider />
                        </template>

                        <template v-if="signing.purchaserX && signing.purchaserX.id">
                            <SigningParty v-model="signing.purchaserX" :key="'dynamicOpusdf'"/>
                        </template>
                        <template v-if="signing.purchaserSide && signing.purchaserSide.length">
                            <template v-for="(signer,signerI) in signing.purchaserSide" >
                                <template v-if="!signer.hidden">
                                    <SigningParty :showError="showError" v-model="signing.purchaserSide[signerI]" :key="signer.id"/>
                                </template>
                            </template>
                        </template>
                        <p v-else class="text-danger">
                            You have no one from {{currentTransaction.type === "purchase" ? "Purchaser" : "Tenant"}} side assigned.
                        </p>

                        <template v-if="signing.vendorSide && signing.vendorSide.length">
							<a-divider />
							<template v-for="(signer,signerI) in signing.vendorSide">
								<SigningParty :showError="showError" :choices="pChoices" v-model="signing.vendorSide[signerI]" :key="signer+signerI" />
							</template>
                        </template>

                        <template v-if="signing.other && signing.other.length">
							<a-divider />
                            <div v-for="(signer,signerI) in signing.other" :key="signer.id + signerI">
                                <SigningParty :showError="showError" :choices="pChoices" :additional="true" v-model="signing.other[signerI]" @delete="signing.other.splice(signerI,1)" />
                            </div>
                        </template>

                        <a-divider />

						<a-button icon="plus" type="primary" size="large" @click="addRecipient">Additional Recipients</a-button>
                    </div>
                </div>
            </div>
        </transition>

        <div slot="footer" class="dF jSB">
            <template>
                <a-button @click="createStep = 0" :disabled="loadModal" size="large" v-if="createStep > 0">BACK</a-button>
                <span v-else></span>
                <!-- <a-button v-if="controller.envelope && createStep === 1" :disabled="loadModal" :icon="loadModal? 'loading' : showRoute? '' : 'eye' " @click="showRoute = !showRoute" size="large" type="primary">{{showRoute? 'SET ORDER' : 'PREVIEW'}}</a-button> -->
                <div class="dF" v-if="controller.envelope && createStep === 1">
                    <a-switch v-model="showRoute"  :disabled="loadModal">
                        <a-icon slot="checkedChildren" type="check" />
                        <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                    <span class="ml-2">
                        Preview Signing Route
                    </span>
                </div>
                <a-button :disabled="loadModal || (!integrated.active && !newDoc.isManual) || (newDoc.isManual && !newDoc.template)" :icon="loadModal? 'loading' : ''" @click="next" size="large" type="primary">{{nextButtonText}}</a-button>
            </template>

        </div>
    </a-modal>
</template>

<script>
import {setProp,validateEmail} from 'bh-mod'
import SigningParty from '@/components/forms/SigningParty'
import SignRoutePreview from '@/components/forms/SignRoutePreview'
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
export default {
    props:['controller'],
    components:{SigningParty,SignRoutePreview,ImageBoxSelector},
    data(){
        return {
            showError:false,
            esign:false,
            transformedTransaction:{},
            showRoute:false,
            loadModal:false,
            createStep:0,
            customFields:{},
            chosenPurchaser:{},
			requiresRepReview: false,
            newDoc:{
                name:'',
                template:'',
                isManual:false,
				docType: 'amendment',
                questions:{
                    balance:0,
                    deposits:[],
                    customFields:{},
                    inclusions:[],
                    purchaserX:''
                },
                customFields:{},
            },
            contractQuestions:{
                inclusions:false,
                deposits:0,
                choosePurchaser:false
            },
            signing:{
                purchaserX:{},
                purchaserSide:[],
                vendorSide:[],
				sellerSide: [],
                other:[]
            }


        }
    },
     watch:{

         createStep(v){
             if (v === 0) this.showRoute = false
         },
        'controller.show'(val){
            if (val){
                this.newDoc = {
                    name:'',
                    template:'',
                    isManual:false,
					docType: 'amendment',
                    questions:{
                        balance:0,
                        deposits:[],
                        customFields:{},
                        inclusions:[],
                        purchaserX:''
                    },
                    customFields:{},
                }
                if (this.integrated.active){
                    this.newDoc.isManual = false
                } else {
                    this.newDoc.isManual = true
                }
            }
            if (this.$refs.newDoc){
                this.$refs.newDoc.resetFields()
            }
            this.envelopeData = null
            this.pagesArray = []
            this.esign = false
            this.createStep = 0
            this.showRoute = false
        },
        'newDoc.template'(val){

            this.newDoc.questions = {
                balance:0,
                deposits:[],
                customFields:{},
                inclusions:[],
                purchaserX:''
            }
            this.newDoc.name = ''
            this.newDoc.customFields = {}

            this.signing = {
                purchaserSide:[],
                vendorSide:[],
				sellerSide:[],
                other:[]
            }

            if (!val) return
            if (!this.chosenDoc) return

            this.loadModal = true
            setTimeout(() => {
                this.loadModal = false
            }, 2000);

            let signingObj = {
                solicitor:false,
                agent:false,
                signingParty:false,
                purchasers:false,
                purchaserSide:true,
                action:'cc',
                actionDisabled:false,
                disabled:false,
                other:true,
                predefined:true,
                id:Date.now(),
                name:'',
                role:'',
                supportingText:'',
                email:'',
                note:'',
                choice:'other',
            }

            this.newDoc.name = this.chosenDoc.name || 'Unnamed Document'

			let pages = this.chosenDoc.pages
			let transaction = this.currentTransaction
			if (this.$route.meta.reservation) {
				transaction = { ...this.currentReservation, type: 'purchase' }
			}
            let questions = []

			let sellerSide = [];
            let purchaserSide = []
            let vendorSide = {}

            let cF = this.chosenDoc.customFields || {}
            let customFields = {}

            let maxDeposit = 0
            let maxPurchasers = 0
            let maxSigningParties = 0
            let maxSellersAgents = 0

            let allPurchasers = []
            let signingPurchasers = []
            let signingParties = []
            let allSigningParties = []
            let allSellersAgents = []
            let signingReps = {
                solicitor:false,
                agent:false,
            }
            let signingList = {
                purchaserX:{},
                purchasers:{},
                reps:{},
                signingParty:{},
				sellersAgents: {}
            }

            this.latestOrder = 0

            let defaultParticipants = this.appSettings.participants
            let defaultSellersAgents = this.appSettings.sellersAgents

			const transactionType = transaction.type === "purchase" ? "Purchaser" : "Tenant";

            pages.forEach( (page,pageI) => {
                let {fields = []} = page
                fields.forEach(spot => {
                    let {field, text} = spot

                    if (field.custom && cF[field.value] && !customFields[field.value] ){
                        customFields[field.value] = cF[field.value]
                        let fieldKey = field.value.replace('other.customFields.', '')
                        if (field.type == 'multiple' && cF[field.value].configure.defaultValue != ''){
                            setProp(this.newDoc.customFields, [fieldKey],cF[field.value].configure.options[parseInt(cF[field.value].configure.defaultValue)].value)
                        } else {
                            setProp(this.newDoc.customFields, [fieldKey],cF[field.value].configure.defaultValue)
                        }
                    }

                    if (field.deposit && text.deposit && text.deposit > this.contractQuestions.deposits){
                        this.contractQuestions.deposits = text.deposit
                        maxDeposit = text.deposit
                    }

                    if (field.inclusions && !this.contractQuestions.inclusions){
                        this.contractQuestions.inclusions = true
                    }

                    let fV = field.value
                    let fT = field.type
                    let canSign = false
                    if  (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
                        canSign = true
                    }

                    if (fV.includes('purchasers[]')) {
                        if (text.purchasers > maxPurchasers) maxPurchasers = text.purchasers
                        if (!allPurchasers.includes(text.purchasers)) allPurchasers.push(text.purchasers)
                        if (fT === 'initial' || fT === 'sign' || fT === 'signedDate'){
                            if (!signingParties.includes(text.purchasers)) signingParties.push(text.purchasers)
                            if (transaction.purchasers[text.purchasers-1] && transaction.purchasers[text.purchasers-1].email){
                                if (!signingList.purchasers[text.purchasers]){
                                    signingList.purchasers[text.purchasers] = {
                                        ...signingObj,
                                        canSign,
                                        actionDisabled:true,
                                        disabled:true,
                                        order:1,
                                        id:`${transactionType}${text.purchasers},`,
                                        action:canSign ? 'sign' : 'cc',
                                        name:'',
                                        role:`${transactionType} #${text.purchasers}`,
                                        supportingText:`${transactionType} #${text.purchasers}`,
                                        email:'',
                                        purchasers:text.purchasers,
                                        signLocations:{}
                                    }
                                }

                                signingList.purchasers[text.purchasers].signLocations[spot.id] = {
                                    ...spot,
                                    name:transaction.purchasers[text.purchasers-1].fullName,
                                    email:transaction.purchasers[text.purchasers-1].email,
                                    page:pageI,
                                    file:0,
                                }
                            }
                        }
                    }

                    if (fV.includes('purchaserX')){
                        if (!this.contractQuestions.choosePurchaser) this.contractQuestions.choosePurchaser = true
                        if (transaction.purchasers[0] && transaction.purchasers[0].email){
                            if (fT === 'initial' || fT === 'sign' || fT === 'signedDate'){
                                if (!signingList.purchaserX.id){
                                    signingList.purchaserX = {
                                        ...signingObj,
                                        canSign,
                                        actionDisabled:true,
                                        disabled:true,
                                        choosePurchaser:true,
                                        order:1,
                                        id:`PurchaserX`,
                                        action:canSign ? 'sign' : 'cc',
                                        name:'',
                                        role:`Dynamic ${transactionType}`,
                                        supportingText:`Dynamic ${transactionType}`,
                                        email:'',
                                        purchasers:text.purchasers,
                                        signLocations:{}
                                    }

                                }

                                signingList.purchaserX.signLocations[spot.id] = {
                                    ...spot,
                                    page:pageI,
                                    file:0,
                                }
                            }
                        }
                    }

                    if (fV.includes('signingParty[]') ) {
                        if (text.signingParty > maxSigningParties) maxSigningParties = text.signingParty
                        if (!allSigningParties.includes(text.signingParty)) allSigningParties.push(text.signingParty)

                        if (fT === 'initial' || fT === 'sign' || fT === 'signedDate'){
                            if (!signingParties.includes(text.signingParty)) signingParties.push(text.signingParty)

                            if (!signingList.signingParty[text.signingParty]){
                                signingList.signingParty[text.signingParty] = {
                                    ...signingObj,
                                    canSign,
                                    actionDisabled:true,
                                    disabled:false,
                                    order:1,
                                    signingParty:text.signingParty,
                                    id:'signingParty' + text.signingParty,
                                    action:canSign ? 'sign' : 'cc',
                                    name:'',
                                    role:text.signingParty === 1 ? `Main Signing Party` : `Additional Signing Party #${text.signingParty - 1}`,
                                    supportingText:text.signingParty === 1 ? `Main Signing Party` : `Additional Signing Party #${text.signingParty - 1}`,
                                    email:'',
                                    signLocations:{}
                                }
                            }

                            signingList.signingParty[text.signingParty].signLocations[spot.id] = {
                                ...spot,
                                page:pageI,
                                file:0,
                            }
                        }
                    }

					if (fV.includes('sellersAgents[]')) {
						if (text.sellersAgents > maxSellersAgents) maxSellersAgents = text.sellersAgents
						if (!allSellersAgents.includes(text.sellersAgents)) allSellersAgents.push(text.sellersAgents)

						if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
							if (!signingParties.includes(text.sellersAgents)) signingParties.push(text.sellersAgents)

							if (!signingList.sellersAgents[text.sellersAgents]) {
								signingList.sellersAgents[text.sellersAgents] = {
									...signingObj,
									canSign,
									actionDisabled: true,
									disabled: false,
									order: 1,
									sellersAgents: text.sellersAgents,
									id: 'sellersAgents' + text.sellersAgents,
									action: canSign ? 'sign' : 'cc',
									name: '',
									role: text.sellersAgents === 1 ? `Main Seller's Agent` : `Additional Seller's Agent #${text.sellersAgents - 1}`,
									supportingText: text.sellersAgents === 1 ? `Main Seller's Agent` : `Additional Seller's Agent #${text.sellersAgents - 1}`,
									email: '',
									signLocations: {}
								}
							}

							signingList.sellersAgents[text.sellersAgents].signLocations[spot.id] = {
								...spot,
								page: pageI,
								file: 0,
							}
						}
					}

                    if (fT === 'initial' || fT === 'sign' || fT === 'signedDate'){
                        if (field.value.includes('reps.agent')) {

                            signingReps.agent = true
                            if (transaction.reps && transaction.reps.agent && transaction.reps.agent.fullName && transaction.reps.agent.email){

                                if (!signingList.reps.agent){

                                    signingList.reps.agent = {
                                        ...signingObj,
                                        canSign,
                                        actionDisabled:false,
                                        disabled:true,
                                        order:1,
                                        id:'agent',
                                        action:canSign ? 'sign' : 'cc',
                                        name:'',
                                        role:`${transactionType}'s Agent`,
                                        supportingText:`${transactionType}'s Agent`,
                                        email:'',
                                        signLocations:{}
                                    }


                                }
                                signingList.reps.agent.signLocations[spot.id] = {
                                    ...spot,
                                    name:transaction.reps.agent.fullName,
                                    email:transaction.reps.agent.email,
                                    page:pageI,
                                    file:0,
                                }
                            }




                        }


                        if (field.value.includes('reps.solicitor')) {
                            signingReps.solicitor = true
                            if (transaction.reps && transaction.reps.solicitor && transaction.reps.solicitor.fullName && transaction.reps.solicitor.email){


                                if (!signingList.reps.solicitor){

                                    signingList.reps.solicitor = {
                                        ...signingObj,
                                        canSign,
                                        actionDisabled:false,
                                        disabled:true,
                                        order:1,
                                        id:'solicitor',
                                        action:canSign ? 'sign' : 'cc',
                                        name:'',
                                        role:`${transactionType}'s Lawyer`,
                                        supportingText:`${transactionType}'s Lawyer`,
                                        email:'',
                                        signLocations:{},
                                    }
                                }


                                signingList.reps.solicitor.signLocations[spot.id] = {
                                    ...spot,
                                    name:transaction.reps.solicitor.fullName,
                                    email:transaction.reps.solicitor.email,
                                    page:pageI,
                                    file:0,
                                }
                            }

                        }

                    }
                })

            })

			allSellersAgents.forEach((sA,sAi) => {
				let canSign = false
                let obj = signingObj
                if (signingList.sellersAgents[sA]) {
                    obj = signingList.sellersAgents[sA]
                    canSign = true
                }

                let agent = {
                    ...obj,
                    roleDisabled:true,
                    canSign,
                    disabled:false,
                    vendor:false,
                    order:this.latestOrder+= 1,
                    id:Date.now() + sAi,
                    action:canSign ? 'sign' : 'cc',
                    name:'',
                    email:'',
                    supportingText:sA === 1 ? `Main Seller's Agent` : `Additional Seller's Agent #${sA-1}`,
                    role:sA === 1 ? `Main Seller's Agent` : `Additional Seller's Agent #${sA-1}`,
                    choice:'other',
                    actionDisabled:false,
                }

                if (defaultSellersAgents[sAi]){
                    agent = {
                        ...agent,
                        choice:defaultSellersAgents[sAi].email,
                        name: defaultSellersAgents[sAi].name,
                        role:sA === 1 ? `Main Seller's Agent` : `Additional Seller's Agent #${sA-1}`,
                        email:defaultSellersAgents[sAi].email,
						company: defaultSellersAgents[sAi].company,
						recoRegistrationNumber: defaultSellersAgents[sAi].recoRegistrationNumber,
						id: defaultSellersAgents[sAi].id || agent.id
                    }
                }
                sellerSide[sA] = agent
			})

			let latestOrder = this.latestOrder + 1

            purchaserSide = transaction.purchasers.map( (purchaser,purchaserI) => {
                this.latestOrder = latestOrder
                if (signingList.purchasers[purchaserI+1]){
                    signingList.purchasers[purchaserI+1] = {
                        ...signingList.purchasers[purchaserI+1],
                        name:purchaser.fullName,
                        id:`${transactionType}${purchaserI + 1}_sign`,
                        supportingText:`${transactionType} #${purchaserI+1}`,
                        role:`${transactionType} #${purchaserI+1}`,
                        order:this.latestOrder,
                        email:purchaser.email,
						actionDisabled: this.controller.type === 'regular' ? false : signingList.purchasers[purchaserI+1].actionDisabled,
						forceActionDisabled: this.controller.type === 'regular' ? true : false
                    }
                    return signingList.purchasers[purchaserI+1]
                }

                return {
                    ...signingObj,
                    canSign:false,
                    actionDisabled:false,
                    disabled:true,
                    order:this.latestOrder,
                    id:purchaser.id,
                    action:'cc',
                    name:purchaser.fullName,
                    role:`${transactionType} #${purchaserI+1}`,
                    supportingText:`${transactionType} #${purchaserI+1}`,
                    email:purchaser.email,
                    purchasers:purchaserI+1,
                }
            })

            if (transaction.reps && transaction.reps.agent && transaction.reps.agent.email){
                let person = transaction.reps.agent
                let canSign = Boolean(signingList.reps.agent)
                let agent = {
                    ...signingList.reps.agent,
                    actionDisabled:false,
                    disabled:true,
                    canBeIgnored:true,
                    order:this.latestOrder+=1,
                    id:'agent',
                    canSign,
                    action:canSign ? 'sign' : 'cc',
                    name:person.firstName + ' ' + person.lastName,
                    role:`${transactionType}'s Agent`,
                    supportingText:`${transactionType}'s Agent`,
                    email:person.email,
                }
                purchaserSide.push(agent)
            }

            if (transaction.reps && transaction.reps.solicitor && transaction.reps.solicitor.email){
                let person = transaction.reps.solicitor
                let canSign = Boolean(signingList.reps.solicitor)
                let solicitor = {
                    ...signingList.reps.solicitor,
                    other:false,
                    canBeIgnored:true,
                    id:'solicitor',
                    actionDisabled:false,
                    disabled:true,
                    order:this.latestOrder+=1,
                    canSign,
                    action:canSign ? 'sign' : 'cc',
                    name:person.firstName + ' ' + person.lastName ,
                    email:person.email,
                }
                purchaserSide.push(solicitor)
            }

            allSigningParties.forEach((sP,sPi) => {
                let canSign = false
                let obj = signingObj
                if (signingList.signingParty[sP]) {
                    obj = signingList.signingParty[sP]
                    canSign = true
                }

                let party = {
                    ...obj,
                    roleDisabled:true,
                    canSign,
                    disabled:false,
                    vendor:true,
                    order:this.latestOrder+= 1,
                    id:Date.now() + sPi,
                    action:canSign ? 'sign' : 'cc',
                    name:'',
                    email:'',
                    supportingText:sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP-1}`,
                    role:sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP-1}`,
                    choice:'other',
                    actionDisabled:false,
                }

                if (defaultParticipants[sPi]){
                    party = {
                        ...party,
                        choice:defaultParticipants[sPi].email,
                        name:defaultParticipants[sPi].name,
                        role:sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP-1}`,
                        email:defaultParticipants[sPi].email,
						company: defaultParticipants[sPi].company,
						id: defaultParticipants[sPi].id || party.id
                    }
                }
                vendorSide[sP] = party
            })

            this.signing.purchaserX = signingList.purchaserX
            this.signing.vendorSide = Object.values(vendorSide)
            this.signing.sellerSide = Object.values(sellerSide)
            this.signing.purchaserSide = purchaserSide

			let customFieldsSorted = Object.values(customFields).sort((a, b) => {
				var regex = /^(\D+) (\d+)$/;
				var matchA = a.name.match(regex);
				var matchB = b.name.match(regex);

				if (matchA && matchB) {
					var prefixA = matchA[1];
					var numA = parseInt(matchA[2]);
					var prefixB = matchB[1];
					var numB = parseInt(matchB[2]);

					if (prefixA !== prefixB) {
						return prefixA.localeCompare(prefixB);
					}

					return numA - numB;
				} else {
					return a.name.localeCompare(b.name);
				}
			});
            this.customFields = customFieldsSorted
        }
    },
    computed:{
        inclusions(){
            if (!this.$store.getters.appSettings) return []
            let incs = this.$store.getters.appSettings && this.$store.getters.appSettings.options && this.$store.getters.appSettings.options.inclusions

            let result = []
            if (incs) result = Object.values(incs)
            return result.map(inc => ({
                label:inc.title,
                value:inc.id,
				body: inc.body
            }))
        },
        nextButtonText(){
            if (!this.controller.envelope) return 'REVIEW & CREATE'
            if (this.createStep === 0) return this.newDoc.isManual ? 'REVIEW & CREATE' : 'NEXT'
            return 'REVIEW & SEND'
        },
        integrated(){
            return this.$store.state.appData.vendors.docusign
        },
        modalTitle(){
            let title = this.controller.envelope? 'Create Envelope for Signing' : 'Create Supporting Document'

            if (this.controller.envelope && this.controller.type !== 'regular'){
                title = this.controller.type === 'release' ? 'Create Notice of Mutual Release' : 'Create Notice of Fulfillment'
            }

            return title
        },
        pChoices() {
			let participants = this.appSettings.participants

			let result = participants
			if (this.currentTransaction.signingParty) result = [...result, ...Object.values(this.currentTransaction.signingParty)]
			if (this.currentTransaction.owners) result = [...result, ...this.currentTransaction.owners.map(x => ({ ...x, name: `${x.firstName} ${x.lastName}`, role: 'Transaction Owner' }))]

			let finalResult = {}
			result.forEach((p) => {
				if (!finalResult[p.email]) {
					finalResult[p.email] = p
				}
			})

			return Object.values(finalResult)
		},
		sellersAgents() {
			return this.appSettings.sellersAgents || [];
		},
        signError(){
            let result = []
            this.signing.vendorSide.forEach( p => {
                result.push(Boolean(p.canSign && validateEmail(p.email)))
            })
			this.signing.sellerSide.forEach( p => {
                result.push(Boolean(p.canSign && validateEmail(p.email)))
            })
            return result.includes(false)
        },
        signRoutePreview(){

            let route = {}

			if (this.requiresRepReview) {
				route = {
					0: {
						order: 0,
						signers: [{
							...this.reviewer
						}]
					}
				}
			}

            this.signing.purchaserSide.forEach( p => {
                if (p.hidden) return
                if (!p.name.trim() || !p.email.trim()) return
                if (!route[p.order]) route[p.order] = {order:p.order, signers:[]}
                route[p.order].signers.push(p)
            })
            if (this.signing.purchaserX.email){
                if (!route[this.signing.purchaserX.order]) route[this.signing.purchaserX.order] = {order:this.signing.purchaserX.order, signers:[]}
                route[this.signing.purchaserX.order].signers.push(this.signing.purchaserX)
            }

            this.signing.vendorSide.forEach( p => {
                if (p.hidden) return
                if (!p.name.trim() || !p.email.trim()) return
                if (!route[p.order]) route[p.order] = {order:p.order, signers:[]}
                route[p.order].signers.push(p)
            })

			this.signing.sellerSide.forEach( p => {
                if (p.hidden) return
                if (!p.name.trim() || !p.email.trim()) return
                if (!route[p.order]) route[p.order] = {order:p.order, signers:[]}
                route[p.order].signers.push(p)
            })

            this.signing.other.forEach( p => {
                if (p.hidden) return
                if (!p.name.trim() || !p.email.trim()) return
                if (!route[p.order]) route[p.order] = {order:p.order, signers:[]}
                route[p.order].signers.push(p)
            })



            let routes = Object.values(route).sort( (a,b) => a.order > b.order ? 1 : -1)

            return routes


        },
        appSettings(){
            return this.$store.state.appData.allSettings.app && this.$store.state.appData.allSettings.app.options

        },
        currentTransaction(){
            return this.$store.state.appData.currentTransaction
        },
		currentReservation() {
			return this.$store.state.appData.currentReservation
		},
        chosenDoc(){
            return this.newDoc.template && this.$store.state.appData.templates[this.newDoc.template] || false
        },
        contractTemplates(){
            let templates = this.$store.state.appData.templates || {}
            return Object.values(templates)
        },
		user() {
			return this.$store.state.user.user
		},
		reviewer: {
			get() {
				const user = {
					id: this.user.id,
					order: 0,
					action: 'approve',
					name: this.user.firstName + ' ' + this.user.lastName,
					role: 'Reviewer',
					email: this.user.email
				}

				return user
			},
			set(val) {

			}
		}
    },
    methods:{
        selectMultiple(e, i){
            this.newDoc.customFields[i] = e
        },
        async createEnvelope(){
            let esign = Boolean(this.controller.envelope)

            let template = this.chosenDoc
            let tId = this.currentTransaction.id

            let nameLookup = {
                release:'Notice of Mutual Release',
                // fulfillment:'Notice of Fulfillment',
            }
            let envelope = {
                name:nameLookup[this.controller.type]? nameLookup[this.controller.type] : this.newDoc.name,
                esign,
                isManual:this.newDoc.isManual,
                file:{
                    template:template.id,
                    key:this.envelopeFileKey
                },
                type:this.controller.type || 'regular',
				requiresRepReview: this.requiresRepReview
            }

			if (envelope.type === 'regular') {
				envelope.docType = this.newDoc.docType || 'amendment'
			}

            if (esign){
                envelope.signers = this.signRoutePreview
            }

            let msg = esign? envelope.isManual ? 'Contract Created' : 'Contract sent for Signing!' : 'Supporting Document Created!'
            this.$store.commit('LOAD',true)

			let url = `/envelops/:instance/${tId}`
			if (this.$route.meta.reservation) {
				url = `/reservations/:instance/${this.$route.params.id}/add-mutual-release`
			}

            this.$api.post(url, envelope).then(({ data }) => {
				data.status = 'created'
				this.$store.commit('ADD_ENVELOPE', data)
				this.$store.commit('CLOSE_PREVIEW')
				this.$store.commit('LOAD', false)
				this.$message.success(msg)
				this.close()
			})
            .catch(err => {
                this.$store.commit('LOAD',false)
                if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'An error occurred while generating your contract. Please try again.'))
				}
            })

        },
        async generatePreview(){

            let template = this.chosenDoc
            this.$store.commit('LOAD',true)

            let transaction = JSON.parse(JSON.stringify(this.currentTransaction))
			if (this.$route.meta.reservation) {
				transaction = JSON.parse(JSON.stringify({ ...this.currentReservation, type: 'purchase' }))
			}
            let {productType = 'lowrise'} = this.$store.state.instance

            if (this.chosenPurchaser && this.chosenPurchaser.id){
                transaction.purchaserX = transaction.purchasers.find(x => {
                    return x.id === this.signing.purchaserX.chosenPurchaser
                })
            }

            Object.entries(this.newDoc.customFields).forEach( ([key,value]) => {
                key = key.replace('other.customFields.','')
                if (!transaction.other) transaction.other = {}
                if (!transaction.other.customFields) transaction.other.customFields = {}
                transaction.other.customFields[key] = value
            })

            if (this.newDoc.questions.inclusions && this.newDoc.questions.inclusions.length){
                transaction.offer.inclusions = this.newDoc.questions.inclusions
            }

            if (productType === 'highrise' && transaction.product && transaction.product.unit && transaction.product.unit.package && transaction.product.unit.package.other && transaction.product.unit.package.other.premiums && Object.keys(transaction.product.unit.package.other.premiums).length){
                let newObj = {}
                Object.entries(transaction.product.unit.package.other.premiums).forEach( ([key,value]) => {
                    if (value && value.active){
                        newObj[key] = value
                    }
                })
                transaction.product.unit.package.other.premiums = newObj
            } else if (productType === 'lowrise' && transaction.product && transaction.product.lot && transaction.product.lot.other && transaction.product.lot.other.premiums && Object.keys(transaction.product.lot.other.premiums).length){
                let newObj = {}
                Object.entries(transaction.product.lot.other.premiums).forEach( ([key,value]) => {
                    if (value && value.active){
                        newObj[key] = value
                    }
                })
                transaction.product.lot.other.premiums = newObj;
            }

			if (this.signing.sellerSide && this.signing.sellerSide.length) {
				transaction.sellersAgents = [...this.signing.sellerSide]
			}

			try {
				let {data} = await this.$api.post(`/contract-templates/:instance/${template.id}/preview`, transaction)
				let {data:url} = await this.$api.get(`/contract-templates/:instance/getfile?key=${data}`);

				this.envelopeFileKey = data
				if (this.controller.envelope){
					this.$store.commit('REVIEW_SEND', {preview:url,callback:this.createEnvelope,isManual:this.newDoc.isManual,buttonText:'SEND CONTRACT'})
				} else {
					this.$store.commit('REVIEW_SAVE', {preview:url,callback:this.createEnvelope})
				}
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error occurred while previewing contract. Please try again!'))
				}
			}

            this.$store.commit('LOAD',false)

        },
        close(){
            this.controller.show = false
            this.$emit('close')
        },
        setPurchaserX(id){

            let purchaser = this.currentTransaction.purchasers.find(x => x.id === id)
            this.chosenPurchaser = {...purchaser}
            this.signing.purchaserX = {
                ...this.signing.purchaserX,
                name:purchaser.fullName,
                email:purchaser.email,
                chosenPurchaser:purchaser.id
            }

            this.signing.purchaserSide.forEach(prc => {
                if (prc.id === purchaser.id) prc.hidden = true
                else prc.hidden = false
            })
        },
        addRecipient(){

            this.signing.other.push({
                canBeRemoved:true,
                action:'cc',
                canSign:false,
                vendor:true,
                id:Date.now(),
                name:'',
                order:this.latestOrder+=1,
                role:'',
                supportingText:'',
                email:'',
                note:'',
                choice:'other',
            })
        },
        next(){

            if (this.newDoc.isManual){
                return this.generatePreview()
            }
            if (this.controller.envelope) {
                this.showError = false
                if (!this.newDoc.template) return this.$message.error('Please select a template')
                if (this.createStep === 0) return this.createStep = 1
                if (this.signError) return this.showError = true
                return this.generatePreview()
            }
            else this.generatePreview()
        },
        closeCreateModal(){
            this.controller.show = false

        }
    }

}
</script>

<style>
    .signer-action-icon{
        position: absolute;
        top:10px;
        right:10px;
        background:white;
        pointer-events: none;
        padding:5px;
        border-radius:50%;
        box-shadow:0 2px 10px rgba(100,100,100,0.2);
        font-size:10px;
    }
</style>
